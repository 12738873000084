<template>
    <div>
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>诗词</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/listDynasty' }">朝代</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="detail-info margin-l-sm">
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">

                <el-form-item label="名称：" label-position="left" prop="name">
                    <el-input v-model="form.name" style="width: 200px;" />
                </el-form-item>


                <el-form-item label="排序：" label-position="left" prop="sort">
                    <el-input-number size="mini" v-model="form.sort" label="排序"></el-input-number>
                </el-form-item>


                <el-form-item>
                    <el-button size="small" type="primary" :loading="loading" @click="onSubmit">保存</el-button>
                </el-form-item>
            </el-form>

        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "template-add",
    data() {
        return {
            image: '',
            form: {},
            rules: {
                name: [
                    { required: true, message: '请输入名称', trigger: 'blur' },
                ],
                // pic:[
                // { required: true, message: '请选择图片', trigger: 'change' }
                // ],
                // detail: [
                //     { required: true, message: '请输入作者信息', trigger: 'blur' },
                //     { max: 1000, message: '长度在 1000个字符以内', trigger: 'blur' }

                // ],
                sort: [
                    { required: true, message: '请输入排序', trigger: 'blur' }
                ],
            },
            loading: false
        }
    },
    methods: {
        ...mapActions('listDynasty', ['addDynasty']),
        onSubmit() {
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this.add()
                }
            });
        },
        async add() {
            this.loading = true
            const { data, res_info } = await this.addDynasty(this.form)
            this.loading = false
            if (res_info != 'ok') return
            this.$message.success('添加成功！')
            this.$router.push('/listDynasty')
        },
        onSelect(image) {
            this.image = imageUrl + image.path
            this.form.pic = image.path
        },
        onDelete() {
            this.image = ''
        },
    }
}
</script>

<style scoped>
.margin-l-sm {
    margin-left: 1.25rem;
}
</style>
